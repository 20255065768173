
/*
 *   File : compare-funds.js
 *   Author URI : https://evoqins.com
 *   Description :  To compare upto 4 mutual funds
 *   Integrations : null
 *   Version : v1.1
 */
// import packages
import { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import parse from 'html-react-parser';

// import components
import { GradientButton } from "../../Component/CTA";
import { Icon } from "../../Component/Icon";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import { InvestmentHeader } from "../../Component/Header";
import { BreadCrumb } from "../../Component/BreadCrumb";
import Footer from "../../Component/Footer/footer";
import { CompareSearchModal, InvestGoalModal, PaymentStatusModal, ViewFundManagers } from "../../Component/Modal";

// import style
import Colors from '../../Styles/color.module.scss';

// import services
import APIService from "../../Service/api-service";
import { _getDayOfMonth } from "../../Helper";
import { _getMFInvestmentSummary } from "../../Helper/api";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Compare', url: null },
];

export default function CompareFunds(props) {
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);

    const [searchIndex, setSearchIndex] = useState(0);
    const [selectedFundIDs, setSelectedFundIDs] = useState([]);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [showFundDetail, setShowFundDetail] = useState(true);
    const [showReturns, setShowReturns] = useState(true);
    const [showHoldings, setShowHoldings] = useState(true);
    const [showManager, setShowManager] = useState(true);
    const [showAboutFund, setShowAboutFund] = useState(true);
    const [showTopHoldings, setShowTopHoldings] = useState(true);
    const [fundDetails, setFundDetails] = useState([]);
    const [APILoader, setAPILoader] = useState(false);
    const [showInvestmentModal, setShowInvestModal] = useState({});
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [isNextMonth, setIsNextMonth] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [fundManagers, setFundManagers] = useState([]);
    const [openFundManagerModal, setOpenFundManagerModal] = useState(false);

    useEffect(() => {
        if (openSearchModal === true) {
            const modal = new Modal(document.getElementById("compare-search"), {});
            modal.show();
        }
    }, [openSearchModal]);

    useEffect(() => {
        if (openFundManagerModal === true) {
            const modal = new Modal(document.getElementById("view-fund-managers"), {});
            modal.show();
        }
    }, [openFundManagerModal]);

    useEffect(() => {
        if (selectedFundIDs.length === 0) {
            setFundDetails([]);
        } else {
            _getComparisons();
        }
    }, [selectedFundIDs]);

    // listener for opening invest modal
    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestmentModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    useEffect(() => {
        if (Object.keys(showInvestmentModal).length !== 0) {
            if (USER_DATA.kyc_step < 11) {
                toast.dismiss();
                toast.error("Complete KYC to start investing", {
                    type: 'error'
                });
                setShowInvestModal({});
                return;
            }
            const { date } = _getDayOfMonth();
            _getMFInvestmentSummary(showInvestmentModal.id, true, date)
                .then((response) => {
                    setMfInvestmentData(response);
                    setMountInvestModal(true)
                })
                .catch((response) => {
                    toast.error(response, {
                        type: "error",
                    });
                });

        }
    }, [showInvestmentModal]);

    // Investment modal
    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }

    // handle fund detail accordion
    function _toggleFundDetail() {
        setShowFundDetail(!showFundDetail);
    }

    // handle returns accordion
    function _toggleReturns() {
        setShowReturns(!showReturns)
    }


    // handle holdings accordion
    function _toggleHoldings() {
        setShowHoldings(!showHoldings);
    }

    // handle fund manager accordion
    function _toggleFundManager() {
        setShowManager(!showManager);
    }

    // handle about fund accordion
    function _toggleAbout() {
        setShowAboutFund(!showAboutFund);
    }

    // handle toggle top holdings
    function _toggleTopHoldings() {
        setShowTopHoldings(!showTopHoldings);
    }
    // handle invest button click
    function _handleInvestButton(e, data) {
        e.stopPropagation();
        setShowInvestModal(data);
    }
    // handle add new fund 
    function _openSearchModal(index) {
        setSearchIndex(index);
        setOpenSearchModal(true);
    }

    // Add selected fund to comparison data
    function _handleSearchSelect(item) {
        let fund_ids = [...selectedFundIDs];
        fund_ids.push(item.id);
        setSelectedFundIDs(fund_ids);
    }

    // Remove fund from comparison data
    function _removeSelectedFund(e, index) {
        e.stopPropagation();
        let fund_ids = [...selectedFundIDs];
        fund_ids.splice(index, 1);
        let fund_detail = [...fundDetails];
        fund_detail.splice(index, 1);
        setFundDetails(fund_detail);
        setSelectedFundIDs(fund_ids);
    }

    // open view more fund manager
    function _showFundManagerModal(data) {
        setOpenFundManagerModal(true);
        setFundManagers(data);
    }

    // handle search modal close
    function _closeSearchModal() {
        setOpenSearchModal(false);
    }

    // handle close fund manager
    function _closeFundManagerModal() {
        setOpenFundManagerModal(false);
    }

    // API - get comparison data
    function _getComparisons() {
        setAPILoader(true);
        let url = 'mf-data/compare';
        let payload = JSON.stringify({
            ids: selectedFundIDs
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                // Sorting data based fund selected array to avoid UI mismatch
                const sorted_data = response.data.sort((a, b) => selectedFundIDs.indexOf(a.id) - selectedFundIDs.indexOf(b.id));
                setFundDetails(sorted_data);
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setAPILoader(false);
        })
    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id, is_next_month, mode_of_payment) {
        if (id === null) {
            setOpenPaymentStatusModal(true);
            setIsNextMonth(is_next_month);
            setPaymentStatus(true);
            setPaymentStatusText('Success');
            return
        }
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                // making payment success modal status as processing in case of netbanking and made instant payment
                if (mode_of_payment === 2 && is_next_month !== true) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
            }
        })

    }

    // Inline Card for show and update mf to compare
    function ComparisonCard({ data, index }) {
        return (
            <div className="p-3 h-214px  border-radius-8px border-all-dashed border-primary bg-light-sky cursor-pointer position-relative"
                data-disabled={selectedFundIDs.length < index ? true : false}
                onClick={() => { if (data === null) { _openSearchModal(index) } }}>
                {
                    data === null ?
                        <div className="mt-5 text-center">
                            <img src={require('../../Assets/Images/explore/add-circle.svg').default} />
                            <p className="e-poppins-regular e-font-16 color-primary-color mt-2">Add fund to compare</p>
                        </div>
                        :
                        <div className="row position-relative">
                            <Icon icon="close"
                                size={20}
                                className="position-absolute e-modal-close-btn right-0 top-0 w-max-content"
                                onClick={(e) => _removeSelectedFund(e, index)} />
                            <div className="col-12 text-center mt-2">
                                <div className="d-flex justify-content-center ">
                                    <div className="p-2 border-radius-8px border-all bg-white border-bright-gray w-max-content">
                                        <img src={data.image} alt={data.name}
                                            width={40}
                                            height={40}
                                            className="object-fit-contain" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 text-center mt-3">
                                <h6 className="e-poppins-semi-bold e-font-12 line-height-18px color-black">{data.name}</h6>
                            </div>
                        </div>
                }
                {
                    data !== null &&
                    <GradientButton label="Invest now"
                        className="py-2 px-3 position-absolute left-0 right-0 margin-auto w-max-content bottom-10"
                        handleClick={(e) => _handleInvestButton(e, data)} />
                }
            </div>
        )
    };

    // Fund details table
    function FundDetailTable() {
        return (
            <table className="e-compare-table">
                <tbody>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Risk</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">{fundDetails[0].risk}</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">{fundDetails.length > 1 ? fundDetails[1].risk : '-'}</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">{fundDetails.length > 2 ? fundDetails[2].risk : '-'}</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">{fundDetails.length > 3 ? fundDetails[3].risk : '-'}</td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Min.SIP</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space"><span className="e-inter-regular">₹</span> {fundDetails[0].min_sip_amount.toLocaleString('en-IN')}</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].min_sip_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[1].min_sip_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[1].min_sip_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[1].min_sip_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[1].min_sip_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[1].min_sip_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Min.Purchase</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].min_lumpsum_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[0].min_lumpsum_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].min_lumpsum_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[1].min_lumpsum_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].min_lumpsum_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[2].min_lumpsum_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[2].min_lumpsum_amount ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[3].min_lumpsum_amount.toLocaleString('en-IN')}
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Nav</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].nav.current_nav ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[0].nav.current_nav.toLocaleString('en-IN')}
                                        ({fundDetails[0].nav.nav_date})
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].nav.current_nav ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[1].nav.current_nav.toLocaleString('en-IN')}
                                        ({fundDetails[1].nav.nav_date})
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].nav.current_nav ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[2].nav.current_nav.toLocaleString('en-IN')}
                                        ({fundDetails[2].nav.nav_date})
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].nav.current_nav ? (
                                    <>
                                        <span className="e-inter-regular">₹</span>
                                        {fundDetails[3].nav.current_nav.toLocaleString('en-IN')}
                                        ({fundDetails[3].nav.nav_date})
                                    </>
                                ) :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title d-flex w-100">
                            CRISIL Ranking
                            {
                                Object.keys(TOOL_TIP).length !== 0 &&
                                <div className="position-relative">
                                    <Icon icon="info"
                                        size={24}
                                        className="cursor-pointer ms-2"
                                        data-tooltip-id="rank-tooltip" />

                                    <Tooltip id="rank-tooltip"
                                        arrowColor='transparent'
                                        effect="float"
                                        place="top"
                                        className='e-tooltip-general'>
                                        {parse(TOOL_TIP.explore_rank_column_heading)}
                                    </Tooltip>
                                </div>
                            }

                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails[0].rank ? fundDetails[0].rank : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 1 && fundDetails[1].rank ? fundDetails[1].rank : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 2 && fundDetails[2].rank ? fundDetails[2].rank : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 3 && fundDetails[3].rank ? fundDetails[3].rank : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Expense Ratio</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails[0].expense_ratio ? fundDetails[0].expense_ratio : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 1 && fundDetails[1].expense_ratio ? fundDetails[1].expense_ratio : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 2 && fundDetails[2].expense_ratio ? fundDetails[2].expense_ratio : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 3 && fundDetails[3].expense_ratio ? fundDetails[3].expense_ratio : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Exit Load</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails[0].exit_load ? fundDetails[0].exit_load : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 1 && fundDetails[1].exit_load ? fundDetails[1].exit_load : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 2 && fundDetails[2].exit_load ? fundDetails[2].exit_load : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 3 && fundDetails[3].exit_load ? fundDetails[3].exit_load : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">AUM</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails[0].aum ? fundDetails[0].aum : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 1 && fundDetails[1].aum ? fundDetails[1].aum : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 2 && fundDetails[2].aum ? fundDetails[2].aum : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 3 && fundDetails[3].aum ? fundDetails[3].aum : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Fund Inception</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails[0].launch_date ? fundDetails[0].launch_date : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 1 && fundDetails[1].launch_date ? fundDetails[1].launch_date : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 2 && fundDetails[2].launch_date ? fundDetails[2].launch_date : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 3 && fundDetails[3].launch_date ? fundDetails[3].launch_date : '-'}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    // Returns table
    function ReturnsTable() {
        return (
            <table className="e-compare-table">
                <tbody>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">3 Months</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                Array.isArray(fundDetails[0].returns) && fundDetails[0].returns.find(item => item.filter_id === 2) ?
                                    <span className={fundDetails[0].returns.find(item => item.filter_id === 2).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[0].returns.find(item => item.filter_id === 2).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[0].returns.find(item => item.filter_id === 2).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && Array.isArray(fundDetails[1].returns) && fundDetails[1].returns.find(item => item.filter_id === 2) ?
                                    <span className={fundDetails[1].returns.find(item => item.filter_id === 2).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[1].returns.find(item => item.filter_id === 2).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[1].returns.find(item => item.filter_id === 2).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && Array.isArray(fundDetails[2].returns) && fundDetails[2].returns.find(item => item.filter_id === 2) ?
                                    <span className={fundDetails[2].returns.find(item => item.filter_id === 2).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[2].returns.find(item => item.filter_id === 2).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[2].returns.find(item => item.filter_id === 2).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && Array.isArray(fundDetails[3].returns) && fundDetails[3].returns.find(item => item.filter_id === 2) ?
                                    <span className={fundDetails[3].returns.find(item => item.filter_id === 2).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[3].returns.find(item => item.filter_id === 2).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[3].returns.find(item => item.filter_id === 2).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">6 Months</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                Array.isArray(fundDetails[0].returns) && fundDetails[0].returns.find(item => item.filter_id === 3) ?
                                    <span className={fundDetails[0].returns.find(item => item.filter_id === 3).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[0].returns.find(item => item.filter_id === 3).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[0].returns.find(item => item.filter_id === 3).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && Array.isArray(fundDetails[1].returns) && fundDetails[1].returns.find(item => item.filter_id === 3) ?
                                    <span className={fundDetails[1].returns.find(item => item.filter_id === 3).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[1].returns.find(item => item.filter_id === 3).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[1].returns.find(item => item.filter_id === 3).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && Array.isArray(fundDetails[2].returns) && fundDetails[2].returns.find(item => item.filter_id === 3) ?
                                    <span className={fundDetails[2].returns.find(item => item.filter_id === 3).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[2].returns.find(item => item.filter_id === 3).returns_value < 0 ?
                                            "color-red"
                                            :
                                            "color-outer-space"}>
                                        {fundDetails[2].returns.find(item => item.filter_id === 3).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && Array.isArray(fundDetails[3].returns) && fundDetails[3].returns.find(item => item.filter_id === 3) ?
                                    <span className={fundDetails[3].returns.find(item => item.filter_id === 3).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[3].returns.find(item => item.filter_id === 3).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[3].returns.find(item => item.filter_id === 3).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">1 Year</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                Array.isArray(fundDetails[0].returns) && fundDetails[0].returns.find(item => item.filter_id === 4) ?
                                    <span className={fundDetails[0].returns.find(item => item.filter_id === 4).returns_value > 0 ? "color-sea-green" :
                                        fundDetails[0].returns.find(item => item.filter_id === 4).returns_value < 0 ?
                                            "color-red"
                                            : "color-outer-space"}>
                                        {fundDetails[0].returns.find(item => item.filter_id === 4).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && Array.isArray(fundDetails[1].returns) && fundDetails[1].returns.find(item => item.filter_id === 4) ?
                                    <span className={fundDetails[1].returns.find(item => item.filter_id === 4).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[1].returns.find(item => item.filter_id === 4).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[1].returns.find(item => item.filter_id === 4).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && Array.isArray(fundDetails[2].returns) && fundDetails[2].returns.find(item => item.filter_id === 4) ?
                                    <span className={fundDetails[2].returns.find(item => item.filter_id === 4).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[2].returns.find(item => item.filter_id === 4).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[2].returns.find(item => item.filter_id === 4).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && Array.isArray(fundDetails[3].returns) && fundDetails[3].returns.find(item => item.filter_id === 4) ?
                                    <span className={fundDetails[3].returns.find(item => item.filter_id === 4).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[3].returns.find(item => item.filter_id === 4).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[3].returns.find(item => item.filter_id === 4).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">3 Year</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                Array.isArray(fundDetails[0].returns) && fundDetails[0].returns.find(item => item.filter_id === 5) ?
                                    <span className={fundDetails[0].returns.find(item => item.filter_id === 5).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[0].returns.find(item => item.filter_id === 5).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[0].returns.find(item => item.filter_id === 5).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && Array.isArray(fundDetails[1].returns) && fundDetails[1].returns.find(item => item.filter_id === 5) ?
                                    <span className={fundDetails[1].returns.find(item => item.filter_id === 5).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[1].returns.find(item => item.filter_id === 5).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[1].returns.find(item => item.filter_id === 5).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && Array.isArray(fundDetails[2].returns) && fundDetails[2].returns.find(item => item.filter_id === 5) ?
                                    <span className={fundDetails[2].returns.find(item => item.filter_id === 5).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[2].returns.find(item => item.filter_id === 5).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[2].returns.find(item => item.filter_id === 5).returns}
                                    </span> :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && Array.isArray(fundDetails[3].returns) && fundDetails[3].returns.find(item => item.filter_id === 5) ?
                                    <span className={fundDetails[3].returns.find(item => item.filter_id === 5).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[3].returns.find(item => item.filter_id === 5).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[3].returns.find(item => item.filter_id === 5).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">5 Year</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                Array.isArray(fundDetails[0].returns) && fundDetails[0].returns.find(item => item.filter_id === 6) ?
                                    <span className={fundDetails[0].returns.find(item => item.filter_id === 6).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[0].returns.find(item => item.filter_id === 6).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[0].returns.find(item => item.filter_id === 6).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && Array.isArray(fundDetails[1].returns) && fundDetails[1].returns.find(item => item.filter_id === 6) ?
                                    <span className={fundDetails[1].returns.find(item => item.filter_id === 6).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[1].returns.find(item => item.filter_id === 6).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[1].returns.find(item => item.filter_id === 6).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && Array.isArray(fundDetails[2].returns) && fundDetails[2].returns.find(item => item.filter_id === 6) ?
                                    <span className={fundDetails[2].returns.find(item => item.filter_id === 6).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[2].returns.find(item => item.filter_id === 6).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[2].returns.find(item => item.filter_id === 6).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && Array.isArray(fundDetails[3].returns) && fundDetails[3].returns.find(item => item.filter_id === 6) ?
                                    <span className={fundDetails[3].returns.find(item => item.filter_id === 6).returns_value > 0 ?
                                        "color-sea-green" :
                                        fundDetails[3].returns.find(item => item.filter_id === 6).returns_value < 0 ?
                                            "color-red" :
                                            "color-outer-space"}>
                                        {fundDetails[3].returns.find(item => item.filter_id === 6).returns}
                                    </span>
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    // Top holdings
    function TopHoldingsTable() {
        return (
            <table className="e-compare-table">
                <tbody>
                    <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Top 10 Holdings</td>
                    <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                        {
                            fundDetails[0].holdings.length !== 0 &&
                            <div className={`d-flex py-3 `}>
                                <div className="w-75">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Name</h6>
                                </div>
                                <div className="w-25">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Assets</h6>
                                </div>
                            </div>
                        }
                        {
                            fundDetails[0].holdings.length !== 0 ?
                                fundDetails[0].holdings.map((item, key) => {
                                    return (
                                        <div className={`d-flex py-2`}>
                                            <div className="w-75">
                                                <p className="mb-0 pe-3 e-holdings-truncate-second-line">{item.name}</p>
                                            </div>
                                            <div className="w-25">
                                                <p className="mb-0">{item.percentage}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                '-'
                        }
                    </td>
                    <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                        {
                            fundDetails.length > 1 && fundDetails[1].holdings.length !== 0 &&
                            <div className={`d-flex py-3`}>
                                <div className="w-75">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Name</h6>
                                </div>
                                <div className="w-25">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Assets</h6>
                                </div>
                            </div>
                        }
                        {
                            fundDetails.length > 1 && fundDetails[1].holdings.length !== 0 ?
                                fundDetails[1].holdings.map((item, key) => {
                                    return (
                                        <div className={`d-flex py-2`}>
                                            <div className="w-75">
                                                <p className="mb-0 pe-3 e-holdings-truncate-second-line">{item.name}</p>
                                            </div>
                                            <div className="w-25">
                                                <p className="mb-0">{item.percentage}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                '-'
                        }
                    </td>
                    <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                        {
                            fundDetails.length > 2 && fundDetails[2].holdings.length !== 0 &&
                            <div className={`d-flex py-3`}>
                                <div className="w-75">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Name</h6>
                                </div>
                                <div className="w-25">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Assets</h6>
                                </div>
                            </div>
                        }
                        {
                            fundDetails.length > 2 && fundDetails[2].holdings.length !== 0 ?
                                fundDetails[2].holdings.map((item, key) => {
                                    return (
                                        <div className={`d-flex py-2`}>
                                            <div className="w-75">
                                                <p className="mb-0 pe-3 e-holdings-truncate-second-line">{item.name}</p>
                                            </div>
                                            <div className="w-25">
                                                <p className="mb-0">{item.percentage}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                '-'
                        }
                    </td>
                    <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                        {
                            fundDetails.length > 3 && fundDetails[3].holdings.length !== 0 &&
                            <div className={`d-flex py-3`}>
                                <div className="w-75">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Name</h6>
                                </div>
                                <div className="w-25">
                                    <h6 className="e-poppins-medium e-font-16 color-black mb-0">Assets</h6>
                                </div>
                            </div>
                        }
                        {
                            fundDetails.length > 3 && fundDetails[3].holdings.length !== 0 ?
                                fundDetails[3].holdings.map((item, key) => {
                                    return (
                                        <div className={`d-flex py-2`}>
                                            <div className="w-75">
                                                <p className="mb-0 pe-3 e-holdings-truncate-second-line">{item.name}</p>
                                            </div>
                                            <div className="w-25">
                                                <p className="mb-0">{item.percentage}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                '-'
                        }
                    </td>
                </tbody>
            </table>
        )
    }

    // Holdings table
    function HoldingsTable() {
        return (
            <table className="e-compare-table">
                <tbody>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Top 5</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails[0].top_five_holding_per ? fundDetails[0].top_five_holding_per : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails.length > 1 && fundDetails[1].top_five_holding_per ? fundDetails[1].top_five_holding_per : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails.length > 2 && fundDetails[2].top_five_holding_per ? fundDetails[2].top_five_holding_per : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails.length > 3 && fundDetails[3].top_five_holding_per ? fundDetails[3].top_five_holding_per : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Top 10</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails[0].top_ten_holding_per ? fundDetails[0].top_ten_holding_per : '-'}
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails.length > 1 && fundDetails[1].top_ten_holding_per ? fundDetails[1].top_ten_holding_per : '-'}    
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails.length > 2 && fundDetails[2].top_ten_holding_per ? fundDetails[2].top_ten_holding_per : '-'} 
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails.length > 3 && fundDetails[3].top_ten_holding_per ? fundDetails[3].top_ten_holding_per : '-'} 
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Top 20</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            { fundDetails[0].top_twenty_holding_per ? fundDetails[0].top_twenty_holding_per : '-'} 
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 1 && fundDetails[1].top_twenty_holding_per ? fundDetails[1].top_twenty_holding_per : '-'} 
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 2 && fundDetails[2].top_twenty_holding_per ? fundDetails[2].top_twenty_holding_per : '-'} 
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {fundDetails.length > 3 && fundDetails[3].top_twenty_holding_per ? fundDetails[3].top_twenty_holding_per : '-'} 
                        </td>
                    </tr>
                    {/* <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">P/E</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">P/B</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Turnover</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Equity</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Cash</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">-</td>
                    </tr> */}

                </tbody>
            </table>
        )
    }

    // Fund manager table
    function FundManagerTable() {
        return (
            <table className="e-compare-table">
                <tbody>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Name</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].fund_managers.length !== 0 ?
                                    fundDetails[0].fund_managers[0].name
                                    :
                                    '-'
                            }
                            {
                                fundDetails[0].fund_managers.length > 1 &&
                                <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block"
                                    onClick={() => _showFundManagerModal(fundDetails[0].fund_managers)}>{`View more ${fundDetails[0].fund_managers.length - 1}+`}</p>
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].fund_managers.length !== 0 ?
                                    fundDetails[1].fund_managers[0].name
                                    :
                                    '-'
                            }
                            {
                                fundDetails.length > 1 && fundDetails[1].fund_managers.length > 1 &&
                                <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block"
                                    onClick={() => _showFundManagerModal(fundDetails[1].fund_managers)}>{`View more ${fundDetails[1].fund_managers.length - 1}+`}</p>
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].fund_managers.length !== 0 ?
                                    fundDetails[2].fund_managers[0].name
                                    :
                                    '-'
                            }
                            {
                                fundDetails.length > 2 && fundDetails[2].fund_managers.length > 1 &&
                                <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block"
                                    onClick={() => _showFundManagerModal(fundDetails[2].fund_managers)}>{`View more ${fundDetails[2].fund_managers.length - 1}+`}</p>
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].fund_managers.length !== 0 ?
                                    fundDetails[3].fund_managers[0].name
                                    :
                                    '-'
                            }
                            {
                                fundDetails.length > 3 && fundDetails[3].fund_managers.length > 1 &&
                                <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block "
                                    onClick={() => _showFundManagerModal(fundDetails[3].fund_managers)}>{`View more ${fundDetails[3].fund_managers.length - 1}+`}</p>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Duration</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].fund_managers.length !== 0 ?
                                    `${fundDetails[0].fund_managers[0].start_date} - ${fundDetails[0].fund_managers[0].end_date}`
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].fund_managers.length !== 0 ?
                                    `${fundDetails[1].fund_managers[0].start_date} - ${fundDetails[1].fund_managers[0].end_date}`
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].fund_managers.length !== 0 ?
                                    `${fundDetails[2].fund_managers[0].start_date} - ${fundDetails[2].fund_managers[0].end_date}`
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].fund_managers.length !== 0 ?
                                    `${fundDetails[3].fund_managers[0].start_date} - ${fundDetails[3].fund_managers[0].end_date}`
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">About</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].fund_managers.length !== 0 ?
                                    fundDetails[0].fund_managers[0].about
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].fund_managers.length !== 0 ?
                                    fundDetails[1].fund_managers[0].about
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].fund_managers.length !== 0 ?
                                    fundDetails[2].fund_managers[0].about
                                    :
                                    '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].fund_managers.length !== 0 ?
                                    fundDetails[3].fund_managers[0].about
                                    :
                                    '-'
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    // About fund table
    function AboutFundTable() {
        return (
            <table className="e-compare-table">
                <tbody>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Description</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].amc_details.amc_description ? fundDetails[0].amc_details.amc_description : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].amc_details.amc_description ? fundDetails[1].amc_details.amc_description : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].amc_details.amc_description ? fundDetails[2].amc_details.amc_description : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].amc_details.amc_description ? fundDetails[3].amc_details.amc_description : '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">launch Date</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].amc_details.amc_founded ? fundDetails[0].amc_details.amc_founded : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].amc_details.amc_founded ? fundDetails[1].amc_details.amc_founded : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].amc_details.amc_founded ? fundDetails[2].amc_details.amc_founded : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].amc_details.amc_founded ? fundDetails[3].amc_details.amc_founded : '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Custodian</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].amc_details.amc_name ? fundDetails[0].amc_details.amc_name : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].amc_details.amc_name ? fundDetails[1].amc_details.amc_name : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].amc_details.amc_name ? fundDetails[2].amc_details.amc_name : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].amc_details.amc_name ? fundDetails[3].amc_details.amc_name : '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="bg-ice-blue e-poppins-medium e-font-16 line-height-24px color-black e-row-title">Register & Transfer Agent</td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails[0].amc_details.rta_name ? fundDetails[0].amc_details.rta_name : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 1 && fundDetails[1].amc_details.rta_name ? fundDetails[1].amc_details.rta_name : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 2 && fundDetails[2].amc_details.rta_name ? fundDetails[2].amc_details.rta_name : '-'
                            }
                        </td>
                        <td className="e-cell e-poppins-regular e-font-14 line-height-21px color-outer-space">
                            {
                                fundDetails.length > 3 && fundDetails[3].amc_details.rta_name ? fundDetails[3].amc_details.rta_name : '-'
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    return (
        <>
            <div className="e-page-container e-manage-sip margin-80px-bottom">
                <InvestmentHeader />
                <BreadCrumb data={PAGE_NAVIGATION} />

                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">Compare mutual funds</p>
                <div className="e-comparison-wrapper w-100 ">
                    <div className="d-flex e-compare-summary-wrapper">
                        {/* Comapare description card */}
                        <div className="col">
                            <div className="border-all border-primary-color border-radius-8px px-3 h-214px  me-2">
                                <h5 className="e-poppins-semi-bold e-font-14 color-black mt-5 pt-1">Compare funds</h5>
                                <p className="e-poppins-regular e-font-14 line-height-20px color-outer-space">Detailed comparison on
                                    parameters like NAV |
                                    Returns | Risk | Rating|
                                    Analysis</p>
                            </div>
                        </div>
                        {/* Add fund card */}
                        <div className="col">
                            <ComparisonCard data={fundDetails.length ? fundDetails[0] : null} index={0} />
                        </div>
                        <div className="col">
                            <ComparisonCard data={fundDetails.length > 1 ? fundDetails[1] : null} index={1} />
                        </div>
                        <div className="col">
                            <ComparisonCard data={fundDetails.length > 2 ? fundDetails[2] : null} index={2} />
                        </div>
                        <div className="col">
                            <ComparisonCard data={fundDetails.length > 3 ? fundDetails[3] : null} index={3} />
                        </div>
                    </div>
                    {/* Accordion */}
                    {
                        APILoader === true ?
                            <div className="">
                                <CustomLoader />
                            </div>
                            :
                            <>
                                {
                                    fundDetails.length === 0 ?
                                        <div className="mt-5">
                                            <EmptyScreen
                                                title={"Add mutual fund to compare"}
                                                description="Please add mutual fund to compare" />
                                        </div>
                                        :
                                        <div className="row mt-4 pt-3 e-compare-table-wrapper">
                                            {/* Fund detail table accordion */}
                                            <div className="col-12">
                                                <div className="bg-alabaster padding-16px-all">
                                                    <h5 className="e-poppins-semi-bold e-font-16 color-primary-color mb-0 w-max-content cursor-pointer py-1"
                                                        onClick={_toggleFundDetail}>
                                                        Fund details
                                                        <Icon icon="right-arrow"
                                                            size={18}
                                                            color={Colors.primary}
                                                            className={`${showFundDetail === true ? 'e-rotate-right-90' : 'e-rotate-left-90'} ms-2`} />
                                                    </h5>
                                                </div>
                                                {
                                                    showFundDetail === true &&
                                                    <FundDetailTable />
                                                }
                                            </div>
                                            {/* Fund detail returns */}
                                            <div className="col-12">
                                                <div className="bg-alabaster padding-16px-all">
                                                    <h5 className="e-poppins-semi-bold e-font-16 color-primary-color mb-0 w-max-content cursor-pointer py-1"
                                                        onClick={_toggleReturns}>
                                                        Returns
                                                        <Icon icon="right-arrow"
                                                            size={18}
                                                            color={Colors.primary}
                                                            className={`${showReturns === true ? 'e-rotate-right-90' : 'e-rotate-left-90'} ms-2`} />
                                                    </h5>
                                                </div>
                                                {
                                                    showReturns === true &&
                                                    <ReturnsTable />
                                                }
                                            </div>

                                            {/* Holdings analysis */}
                                            <div className="col-12">
                                                <div className="bg-alabaster padding-16px-all">
                                                    <h5 className="e-poppins-semi-bold e-font-16 color-primary-color mb-0 w-max-content cursor-pointer py-1"
                                                        onClick={_toggleHoldings}>
                                                        Holdings analysis
                                                        <Icon icon="right-arrow"
                                                            size={18}
                                                            color={Colors.primary}
                                                            className={`${showHoldings === true ? 'e-rotate-right-90' : 'e-rotate-left-90'} ms-2`} />
                                                    </h5>
                                                </div>
                                                {
                                                    showHoldings === true &&
                                                    <HoldingsTable />
                                                }
                                            </div>

                                            {/* Holdings analysis */}
                                            <div className="col-12">
                                                <div className="bg-alabaster padding-16px-all">
                                                    <h5 className="e-poppins-semi-bold e-font-16 color-primary-color mb-0 w-max-content cursor-pointer py-1"
                                                        onClick={_toggleTopHoldings}>
                                                        Top Holdings
                                                        <Icon icon="right-arrow"
                                                            size={18}
                                                            color={Colors.primary}
                                                            className={`${showTopHoldings === true ? 'e-rotate-right-90' : 'e-rotate-left-90'} ms-2`} />
                                                    </h5>
                                                </div>
                                                {
                                                    showTopHoldings === true &&
                                                    <TopHoldingsTable />
                                                }
                                            </div>

                                            {/* Fund manager */}
                                            <div className="col-12">
                                                <div className="bg-alabaster padding-16px-all">
                                                    <h5 className="e-poppins-semi-bold e-font-16 color-primary-color mb-0 w-max-content cursor-pointer py-1"
                                                        onClick={_toggleFundManager}>
                                                        Fund manager
                                                        <Icon icon="right-arrow"
                                                            size={18}
                                                            color={Colors.primary}
                                                            className={`${showManager === true ? 'e-rotate-right-90' : 'e-rotate-left-90'} ms-2`} />
                                                    </h5>
                                                </div>
                                                {
                                                    showManager === true &&
                                                    <FundManagerTable />
                                                }
                                            </div>
                                            {/* About fund  */}
                                            <div className="col-12">
                                                <div className="bg-alabaster padding-16px-all">
                                                    <h5 className="e-poppins-semi-bold e-font-16 color-primary-color mb-0 w-max-content cursor-pointer py-1"
                                                        onClick={_toggleAbout}>
                                                        About fund
                                                        <Icon icon="right-arrow"
                                                            size={18}
                                                            color={Colors.primary}
                                                            className={`${showAboutFund === true ? 'e-rotate-right-90' : 'e-rotate-left-90'} ms-2`} />
                                                    </h5>
                                                </div>
                                                {
                                                    showAboutFund === true &&
                                                    <AboutFundTable />
                                                }
                                            </div>
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>
            <Footer />
            {
                openSearchModal === true && (
                    <CompareSearchModal close={_closeSearchModal}
                        handleFundSelect={_handleSearchSelect} />
                )
            }
            {
                mountInvestModal === true && (
                    <InvestGoalModal data={showInvestmentModal}
                        mfInvestmentData={mfInvestmentData}
                        close={() => {
                            setShowInvestModal({})
                            setMfInvestmentData({})
                            setMountInvestModal(false)
                        }}
                        getPaymentStatus={_getPaymentStatus} />
                )
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        type={isNextMonth === true ? 5 : 1}
                        close={() => setOpenPaymentStatusModal(false)} />
                )
            }
            {
                openFundManagerModal === true && (
                    <ViewFundManagers data={fundManagers}
                        close={_closeFundManagerModal} />
                )
            }
        </>
    );
};